<template>
    <PageLayout>
        <template>
            <div class="d-flex w-100 justify-space-between mb-6">
                <div class="d-flex align-center">
                    <h1>{{ filter_show ? $t('search_deal') : $t('deal.self') }}</h1>
                    <TableSettings
                        :fields="tableColumns"
                        :entityName="entityName"
                        className="ml-2"
                        @column-select="onColumnSelect"
                    />
                </div>
                <div class="text-right">
                    <v-btn icon>
                        <v-icon @click="$router.push({name: 'deal'})">
                            mdi-view-headline
                        </v-icon>
                    </v-btn>
                    <v-btn icon @click="$router.push({name: 'deal.kanban'})">
                        <v-icon>
                            mdi-format-columns
                        </v-icon>
                    </v-btn>
                    <Btn
                        v-if="$vuetify.breakpoint.smAndUp"
                        :disabled="userBlock"
                        :to="{name: 'deal.create'}"
                        color="secondary"
                        :title="$t('deal.add')"
                    >
                        {{ $t('deal.add') }}
                    </Btn>
                    <Teleport to="#app" v-else>
                        <div class="fab__wrapper">
                            <Btn
                                fab
                                :disabled="userBlock"
                                :to="{name: 'deal.create'}"
                                :title="$t('deal.add')"
                                color="secondary"
                            ><v-icon>mdi-plus</v-icon></Btn>
                        </div>
                    </Teleport>
                </div>
            </div>

            <div class="mobile-filter mb-6" v-if="$vuetify.breakpoint.smAndDown">
                <v-icon
                    class="mobile-filter__btn mr-2"
                    :disabled="userBlock"
                    color="secondary"
                    @click="filter_show === true ? filter_show = false : filter_show = true"
                    :title="$t['filter_add']"
                >mdi-filter-plus</v-icon>

                <FilterMobileLinks
                    :links="[
                        ...business_processItems.map((item) => {
                            return {
                                ...item,
                                title: item.name
                            }
                        }),

                        ...Additional.map((item) => {
                            return {
                                ...item
                            }
                        }),

                        ...userFilters.map((item) => {
                            return {
                                ...item,
                                title: item.name
                            }
                        }),
                    ]"
                />
            </div>

            <FilterComponent
                class="mb-6"
                v-if="filter_show"
                :filterData="filterData"
                :currentFilter="currentFilter"
                :value="filterValues"
                :loading="loading"
                :invalid="invalid"
                :entity="entityName"
                @input="filterValues = $event"
                @submit="submitForm"
                @filterSaved="filterSaved"
                @filterDeleted="filterDeleted"
            />

            <template v-if="$vuetify.breakpoint.mobile">
                <ListItem
                    v-for="item in deals"
                    :item="item"
                    :key="item.id"
                    :columns="tableColumns"
                    :userBlock="userBlock"

                    @deleteItem="deleteDeal(item)"
                >
                    <template v-slot:number="{ item }">
                        <div :class="item.deleted ? 'red--text' : ''">
                            <v-hover v-slot="{ hover }">
                                <div>
                                    <v-btn :disabled="userBlock" text plain :to="'/deal/' + item.uuid + '/show'" class="cursor-pointer font_weight_600 button_link">
                                        {{ item.number }}
                                    </v-btn>
                                    <!--                                    <v-icon :disabled="userBlock" v-if="hover" @click="editDeal(item)" class="ml-2" :title="$t('edit')">-->
                                    <!--                                        mdi-pencil mdi-18px-->
                                    <!--                                    </v-icon>-->
                                    <!--                                    <v-icon :disabled="userBlock" v-if="hover && can(['administrator', 'director', 'general_manager'])" @click="deleteDeal(item)" :title="$t('delete')">-->
                                    <!--                                        mdi-delete-outline mdi-18px-->
                                    <!--                                    </v-icon>-->
                                </div>
                            </v-hover>
                        </div>
                    </template>
                    <template v-slot:deal_status_id="{ item }">
                        <div :disabled="userBlock" :style="'color:'+ item.deal_step_color+'!important'">{{ item.deal_status }}</div>
                    </template>
                    <template v-slot:created_at="{ item }">
                        <div>{{ $moment(item.created_at).format('DD.MM.YYYY') }}</div>
                    </template>
                    <template v-slot:admin_id="{ item }">
                        <User item="item.admin" />
                    </template>
                    <template v-slot:active="{ item }">
                        <v-edit-dialog :return-value.sync="item.active" large persistent
                                       @save="activeDeal(item)" :save-text="$t('save')"
                                       :cancel-text="$t('cancel')">
                            <div class="cursor-pointer">{{ item.active ? $t('yes') : $t('no') }}</div>
                            <template v-slot:input>
                                <v-select v-model="item.active" :items="itemYN" item-text="name"
                                          item-value="id"
                                          outlined
                                          solo
                                          flat
                                          dense
                                          :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                          color="primary"
                                          class="pt-5 elevation-0 rounded-lg input_text"
                                ></v-select>
                            </template>
                        </v-edit-dialog>
                    </template>
                    <template v-slot:task="{ item }">
                        <div>
                            <span v-for="task in item.tasks" :key="task.number" >
                                <v-btn text plain :to="showTask(task)" class="cursor-pointer font_weight_500 button_link" :title="task.title">
                                    {{ task.number }}
                                </v-btn>
                            </span>
                        </div>
                    </template>
                    <template v-slot:company_id="{ item }">
                        <div v-if="item.company">
                            <v-btn text plain :to="'/uved/' +  item.company.uuid + '/show'" class="button_link cursor-pointer font_weight_500">
                                {{ item.company.company_name ? item.company.company_name : ( item.company.first_name + ' ' + item.company.last_name ) }}
                            </v-btn>
                        </div>
                    </template>
                    <template v-slot:business_process_id="{ item }">
                        <v-btn text plain :to="'/deal/' +  item.uuid + '/show'" class="button_link cursor-pointer font_weight_500">
                            {{item.business_process.name}}
                        </v-btn>
                    </template>
                </ListItem>
            </template>
            <template v-else>
                <ResizableTable
                    class="main-data-table"
                    :rows="deals"
                    :columns="tableColumns"
                    :sort-by="sortBy"
                    :sort-dir="sortDir"

                    @sort="sortItems"
                    @columnMoved="onColumnsMoved"
                    @columnResized="onColumnResized"
                >
                    <template v-slot:number="{ item }">
                        <div :class="item.deleted ? 'red--text' : ''">
                            <v-hover v-slot="{ hover }">
                                <div>
                                    <v-btn :disabled="userBlock" text plain :to="'/deal/' + item.uuid + '/show'" class="cursor-pointer font_weight_600 button_link" :style="'color:'+ item.deal_step_color+'!important'">
                                        {{ item.number }}
                                    </v-btn>
                                    <!--                                        <v-icon :disabled="userBlock" v-if="hover" @click="editDeal(item)" class="ml-2" :title="$t('edit')">-->
                                    <!--                                            mdi-pencil mdi-18px-->
                                    <!--                                        </v-icon>-->
                                    <v-icon :disabled="userBlock" v-if="hover && can(['administrator', 'director', 'general_manager'])" @click="deleteDeal(item)" :title="$t('delete')">
                                        mdi-delete-outline mdi-18px
                                    </v-icon>
                                </div>
                            </v-hover>
                        </div>
                    </template>
                    <template v-slot:messages="{ item }">
                        <div class="message-budges">
                            <span class="message-budge" v-if="item.unread_messages_count > 0">
                                <v-icon>icon icon-messages</v-icon>
                            </span>
                            <span class="message-budge message-budge--with_mentioning" v-if="item.unread_messages_with_mentioning_count > 0">
                                <v-icon>mdi mdi-at</v-icon>
                            </span>
                            <span class="message-budge" v-if="item.notification_count > 0">
                                <v-icon>icon-notifications</v-icon>
                            </span>
                        </div>
                    </template>
                    <template v-slot:deal_status_id="{ item }">
                        <div :disabled="userBlock" :style="'color:'+ item.deal_step_color+'!important'">{{ item.deal_status }}</div>
                    </template>
                    <template v-slot:created_at="{ item }">
                        <div>{{ $moment(item.created_at).format('DD.MM.YYYY') }}</div>
                    </template>
                    <template v-slot:admin_id="{ item }">
                        <div>
                            <v-list subheader two-line class="background_none py-0">
                                <v-list-item class="px-0 py-0 my-0 min_height_auto">
                                    <v-list-item-avatar size="36" class="my-0 py-0" :class="{'border_blue': item.admin.photo === '/img/avatar.png'}">
                                        <img v-if="item.admin.photo !== '/img/avatar.png'" :src="item.admin.photo" :alt="item.admin.name">
                                        <v-icon v-else color="primary" size="26">
                                            ${{ 'settingsIcon' }}
                                        </v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content class="py-0 title_subtitle">
                                        <v-list-item-title v-text="item.admin.name"></v-list-item-title>
                                        <v-list-item-subtitle v-text="formatPhoneNumber(item.admin.phone)"></v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </div>
                    </template>
                    <template v-slot:active="{ item }">
                        <v-edit-dialog :return-value.sync="item.active" large persistent
                                       @save="activeDeal(item)" :save-text="$t('save')"
                                       :cancel-text="$t('cancel')">
                            <div class="cursor-pointer">{{ item.active ? $t('yes') : $t('no') }}</div>
                            <template v-slot:input>
                                <v-select v-model="item.active" :items="itemYN" item-text="name"
                                          item-value="id"
                                          outlined
                                          solo
                                          flat
                                          dense
                                          :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                          color="primary"
                                          class="pt-5 elevation-0 rounded-lg input_text"
                                ></v-select>
                            </template>
                        </v-edit-dialog>
                    </template>
                    <template v-slot:task="{ item }">
                        <div>
                            <span v-for="task in item.tasks" :key="task.number" >
                                <v-btn text plain :to="showTask(task)" class="cursor-pointer font_weight_500 button_link" :title="task.title" :style="'color:'+ item.deal_step_color+'!important'">
                                    {{ task.number }}
                                </v-btn>
                            </span>
                        </div>
                    </template>
                    <template v-slot:company_id="{ item }">
                        <div v-if="item.company">
                            <v-btn text plain :to="'/uved/' +  item.company.uuid + '/show'" class="button_link cursor-pointer font_weight_500">
                                {{ item.company.company_name ? item.company.company_name : ( item.company.first_name + ' ' + item.company.last_name ) }}
                            </v-btn>
                        </div>
                    </template>
                    <template v-slot:business_process_id="{ item }">
                        <v-btn text plain :to="'/deal/' + item.uuid + '/show'" class="button_link cursor-pointer font_weight_500" :style="'color:'+ item.deal_step_color+'!important'">
                            {{item.business_process.name}}
                        </v-btn>
                    </template>
                    <template v-slot:text="{ item }">
                        <div>{{ item.text !== null  ? truncatedText(item.text) : '' }}</div>
                    </template>
                </ResizableTable>
            </template>
            <div class="main-pagination">
                <v-pagination v-model="page" :total-visible="totalVisiblePag" class="pagination_table" :length="pageCount" :disabled="loading" @input="onPageChange"></v-pagination>
            </div>

            <v-dialog
                transition="dialog-bottom-transition"
                max-width="600"
                v-model="dialogDealStatus"
                class="dialog_body"
            >
                <v-card>
                    <v-toolbar class="dialog_toolbar" elevation="0">
                        <div class="ma-auto ml-0">
                            <span>{{ $t('deal_editing') }}</span>
                        </div>
                        <div>
                            <v-icon @click="closeDealStatus()" :title="$t('close')">mdi-close</v-icon>
                        </div>
                    </v-toolbar>
                    <v-card-text class="pa-2 pb-0">
                        <v-col cols="12">
                            <ValidationProvider ref="deal_status_edit" rules="required" v-slot="{ errors, valid }">
                                <v-select v-model="deal_status_edit" :disabled="loading"
                                          :items="deal_statusItems" :error-messages="errors"
                                          :error="!valid"
                                          item-text="name" item-value="id"
                                          :label="$t('deal_status')"
                                          outlined
                                          solo
                                          flat
                                          dense
                                          hide-details
                                          :height="$vuetify.breakpoint.xsOnly ? 45 : 31"
                                          color="primary"
                                          class="ma-0 pa-0 my-0 py-0 elevation-0 input_text"
                                ></v-select>
                            </ValidationProvider>
                        </v-col>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            @click="setStatusDeal()"
                        >{{ $t('edit') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </template>
        <template v-slot:side>
            <v-icon v-if="$vuetify.breakpoint.mdAndUp" class="mt-2 mb-5" :disabled="userBlock" color="secondary" @click="filter_show===true ? filter_show=false : filter_show=true" :title="$t['filter_add']">mdi-filter-plus</v-icon>

            <v-list class="filter_left" v-if="business_processItems.length > 0 && $vuetify.breakpoint.mdAndUp">
                <v-list-group v-model="group3" :ripple="false" class="px-0">
                    <template v-slot:activator class="px-0">
                        <v-list-item-title :ripple="false" class="main_filter">{{ $t('business_processes') }}</v-list-item-title>
                    </template>
                    <v-list-item :ripple="false" v-for="(item, i) in business_processItems" :key="i" link :to="item.url" :class="{'active': isSameRouteWithQueryParams($route, item.url)}">
                        <v-list-item-title v-text="item.name" class="cursor-pointer"></v-list-item-title>
                    </v-list-item>
                </v-list-group>
            </v-list>

            <v-list :disabled="userBlock" class="filter_left" v-if="Additional.length > 0 && $vuetify.breakpoint.mdAndUp">
                <v-list-group :ripple="false" v-model="group2" class="px-0">
                    <template v-slot:activator class="px-0">
                        <v-list-item-title :ripple="false" class="main_filter">{{ $t('system_filters') }}</v-list-item-title>
                    </template>
                    <v-list-item :ripple="false" v-for="(item, i) in Additional" :key="i" link :to="item.url" :class="{'active': isSameRouteWithQueryParams($route, item.url)}" v-show="item.can">
                        <v-list-item-title v-if="item.can" v-text="item.title" class="cursor-pointer"></v-list-item-title>
                        <v-list-item-icon v-if="item.can" v-text="item.count" class="filter_count"></v-list-item-icon>
                    </v-list-item>
                </v-list-group>
            </v-list>

            <v-list :disabled="userBlock" class="filter_left" v-if="$route.params.id > 0 && $vuetify.breakpoint.mdAndUp">
                <v-list-group :ripple="false" v-model="group1" class="px-0">
                    <template v-slot:activator class="px-0">
                        <v-list-item-title :ripple="false" class="main_filter">{{ $t('status') }}</v-list-item-title>
                    </template>

                    <v-list-item :ripple="false" v-for="(item, i) in deal_statusItems" :key="i" :to="item.url" link :class="item.id === deal_status ? 'active' : ''">
                        <v-list-item-title v-text="item.name" class="cursor-pointer"></v-list-item-title>
                        <v-list-item-icon v-text="'-'" class="filter_count"></v-list-item-icon>
                    </v-list-item>
                </v-list-group>
            </v-list>

            <v-list :disabled="userBlock" v-if="userFilters.length > 0 && $vuetify.breakpoint.mdAndUp" class="filter_left">
                <v-list-group :ripple="false" v-model="userFiltersGroup" class="px-0">
                    <template v-slot:activator class="px-0">
                        <v-list-item-title :ripple="false" class="main_filter">{{ $t('user_filters') }}</v-list-item-title>
                    </template>
                    <v-list-item :ripple="false" v-for="(item, i) in userFilters" :key="i" :class="{'active-item': isSameRouteWithQueryParams($route, item.url)}" :active-class="'no-active'" :to="item.url">
                        <v-list-item-title v-text="item.name" class="cursor-pointer"></v-list-item-title>
                        <v-list-item-icon v-text="item.count" class="filter_count"></v-list-item-icon>
                    </v-list-item>
                </v-list-group>
            </v-list>
        </template>
    </PageLayout>
</template>
<script>

import {mapActions, mapGetters} from "vuex"
import {ValidationProvider} from 'vee-validate'
import FilterComponent from "@/components/FilterComponent.vue";
import FilterView from "@/plugins/mixins/FilterView";
import PageLayout from "@/components/Leentech/PageLayout.vue";
import ResourceView from "@/plugins/mixins/ResourceView";
import ListItem from "@/components/Deal/ListItem.vue";
import FilterMobileLinks from "@/components/FilterMobileLinks.vue";
import Btn from "@/components/Form/Btn.vue";
import User from "@/components/User.vue";

export default {
    name: "Deals",
    components: {
        User,
        Btn,
        FilterMobileLinks,
        ListItem,
        FilterComponent,
        ValidationProvider,
        PageLayout
    },
    mixins: [
        FilterView,
        ResourceView
    ],
    data() {
        return {
            entityName: 'deal',
            invalid: false,
            options: {
                ...FilterView.data().options,
                sortBy: ['id']
            },

            date: null,
            dateDialog: false,
            number: null,
            deal_status_edit: null,
            deal_status: null,
            deal_statusItems: [],
            business_process: 0,
            business_processItems: [],
            fab: false,
            deal: null,
            deals: [],
            dealItems: [],
            sortBy: "created_at",
            sortDir: false,
            page: 1,
            pageCount: 0,
            perPage: 5,
            totalVisiblePag: 7,
            totalDeals: 0,
            loading: false,
            headers: [
                {
                    text: '№',
                    align: "left",
                    value: "number",
                    width: 70
                },
                {
                    text: this.$t('tasks'),
                    align: "left",
                    value: "tasks",
                    width: 70
                },
                {
                    text: this.$t('uved.self'),
                    align: "left",
                    value: "name",
                },
                {
                    text: this.$t('deal_status'),
                    value: "deal_status",
                    width: 120
                },
                {
                    text: this.$t('date'),
                    value: "created_at",
                    width: 120
                },
                {
                    text: this.$t('author'),
                    align: "left",
                    value: "admin",
                    width: 150
                },
                {
                    text: this.$t('active'),
                    align: "center",
                    value: "active",
                    width: 80
                },
            ],
            active: null,
            activeItems: [
                {
                    id: null,
                    name: this.$t('all')
                },
                {
                    id: 1,
                    name: this.$t('yes')
                },
                {
                    id: 0,
                    name: this.$t('no')
                }
            ],
            itemYN: [
                {
                    id: 0,
                    name: this.$t('no'),
                },
                {
                    id: 1,
                    name: this.$t('yes'),
                }
            ],
            system_filters: [],
            action_filter: 'all',
            group1: true,
            group2: true,
            group3: true,
            filter_show: false,
            userFiltersGroup: true,
            dialogDealStatus: false

        };
    },
    computed: {
        ...mapGetters(['userBlock', 'BusinessProcessId', 'lang', 'itemsPerPage', 'perPageItems']),

        filteredHeaders() {
            return this.headers.filter(h => !h.hide)
        },

        baseUrl() {
            return `/deal/${this.$route.params.id}`
        },

        Additional: function () {
            return [
                {
                    'title': this.$t('my_deal'),
                    'count': '-',
                    'action': 'all',
                    'can': 1,
                    'url': {
                        path: `/deal/${this.$route.params.id}`,
                        query: {
                            filter: {
                                everything_except_completed_canceled: {
                                    eq: 1
                                },
                                outbox: {
                                    eq: '1'
                                }
                            }
                        }
                    }
                },
                {
                    'title': this.$t('inbox'),
                    'count': '-',
                    'action': 'all',
                    'can': 1,
                    'url': {
                        path: '/deal/' + this.$route.params.id,
                        query: {
                            filter: {
                                inbox: {
                                    eq: '1'
                                },
                                everything_except_completed_canceled: {
                                    eq: 1
                                }
                            }
                        }
                    }
                },
                // {
                //     'title': this.$t('outbox'),
                //     'count': '-',
                //     'action': 'inbox',
                //     'can': 1,
                //     'url': {
                //         path: '/deal/' + this.$route.params.id,
                //         query: {
                //             filter: {
                //                 outbox: {
                //                     eq: '1'
                //                 }
                //             }
                //         }
                //     }
                // },
                {
                    'title': this.$t('favorite'),
                    'count': '-',
                    'action': 'favorite',
                    'can': 1,
                    'url': {
                        path: '/deal/' + this.$route.params.id,
                        query: {
                            filter: {
                                favorite: {
                                    eq: '1'
                                }
                            }
                        }
                    }
                },
                {
                    'title': this.$t('filter.field.only_completed'),
                    'count': '-',
                    'action': 'only_completed',
                    'can': 1,
                    'url': {
                        path: '/deal/' + this.$route.params.id,
                        query: {
                            filter: {
                                only_completed: {
                                    eq: '1'
                                }
                            }
                        }
                    }
                },
                {
                    'title': this.$t('filter.field.only_canceled'),
                    'count': '-',
                    'action': 'only_canceled',
                    'can': 1,
                    'url': {
                        path: '/deal/' + this.$route.params.id,
                        query: {
                            filter: {
                                only_canceled: {
                                    eq: '1'
                                }
                            }
                        }
                    }
                },
                {
                    'title': this.$t('all_deals'),
                    'count': '-',
                    'action': 'all',
                    'can': 1,
                    'url': {
                        path: `/deal/${this.$route.params.id}`,
                        query: {
                            filter: {
                                everything_except_completed_canceled: {
                                    eq: 1
                                }
                            }
                        }
                    }
                },
                {
                    'title': this.$t('subordinates'),
                    'count': '-',
                    'action': 'admin_all',
                    'can': this.can(['director', 'administrator', 'accountant']),
                    'url': {
                        path: '/deal/' + this.$route.params.id,
                        query: {
                            filter: {
                                all: {
                                    eq: '1'
                                }
                            }
                        }
                    }
                },
            ]
        }
    },

    watch: {
        options: {
            handler() {
                this.getDeals()
            },
            deep: false
        }
    },


    methods: {
        ...mapActions(['setBusinessProcessId']),

        truncatedText(text) {
            if(text !== '' && text.length > 0){
                const words = text.split(' ');
                if (words.length > 10) {
                    return words.slice(0, 10).join(' ') + '...';
                }
                return text;
            }

        },

        init() {
            this.business_process = parseInt(this.$route.params.id);
            this.setBusinessProcessId(this.$route.params.id)
            this.getItems()
            this.getFilters()
            this.getBusinessProcess()
            this.getDealStatuses()
        },

        rowClass() {
            return (!this.$vuetify.breakpoint.xsOnly) ? 'rowClass Table5_Deal' : 'rowMobiClass';
        },

        sendRoute(item) {
            this.deal_status = item.id;
            this.getDeals();
        },

        sendBusinessProcess(item) {
            this.setBusinessProcessId(item.id);
            this.$router.replace({
                name: 'deal_id',
                params: {
                    id: item.id
                }
            })
            // this.business_process = item.id;
            // this.getDealStatuses();
            // this.getDeals();
        },

        canSort(column) {
            if (column === 'name' || column === 'tasks') {
                return false;
            }
            // Здесь вы можете определить, можно ли сортировать столбец
            // Например, можете вернуть true для всех столбцов, которые могут быть сортированы
            return true;
        },

        showDeal(item) {
            this.$router.push({
                name: 'deal.show',
                params: {
                    id: item.uuid
                }
            })
        },

        editDeal(item) {
            this.$router.push({
                name: 'deal.edit',
                params: {
                    id: item.uuid
                }
            })
            // this.$router.push({ path: '/deal/'+item.uuid+'/edit' }, () => {
            //     window.open(this.$router.currentRoute.fullPath, '_blank');
            // });
        },

        sendFilter(item) {
            this.deal_status = null;
            this.action_filter = item.action;
            this.getDeals();
        },

        async deleteDeal(item) {
            if (confirm(this.$t('delete_deal'))) {
                var _this = this
                this.loading = true;
                await this.$http
                    .delete(`admin/deal/${item.id}`)
                    .then(res => {
                        this.$toastr.success(this.$t('deal_has_been_deleted'))
                        this.getDeals()
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('deal_has_not_been_deleted'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.loading = false
                    })
            }
        },

        async getItems() {
            this.getDeals()
        },

        async getDeals(type) {
            this.loading = true;
            const {
                sortBy,
                sortDesc,
                page,
                itemsPerPage
            } = this.options;
            let params = {};
            if (sortBy[0] !== undefined) {
                params.sortBy = sortBy[0];
            }
            if (sortDesc[0] !== undefined) {
                params.sortDir = sortDesc[0] ? 'asc' : 'desc';
            }
            if (page !== undefined) {
                if (type === 'search') {
                    params.page = 1
                } else {
                    params.page = page
                }
            }
            if (itemsPerPage !== undefined) {
                params.perPage = itemsPerPage;
            }
            if (this.number) {
                params.number = this.number;
            }

            if (this.date) {
                params.date = this.date;
            }

            if (this.business_process) {
                params.business_process = this.business_process;
            }

            if (this.action_filter === 'admin_all') {
                params.deal_status = -2;
            }

            if (this.deal_status) {
                params.deal_status = this.deal_status;
            }

            if (this.action_filter === 'inbox') {
                params.inbox = 1;
            }
            if (this.action_filter === 'outbox') {
                params.outbox = 1;
            }
            if (this.action_filter === 'favorite') {
                params.favorite = 1;
            }

            params.active = this.active

            if (this.filterValues) {
                params.fltr = this.filterValues;
            }

            await this.$http
                .get("admin/deal", {
                    params: params,
                })
                .then(res => {
                    this.filterData = res.body.filter;
                    this.deals = res.body.data
                    this.page = res.body.meta.current_page
                    this.totalDeals = res.body.meta.total
                    this.pageCount = res.body.meta.last_page
                })
                .catch(err => {
                    this.deals = []
                    this.totalDeals = 0
                    this.$toastr.error(this.$t('failed_to_get_list_deals'))
                })
                .finally(end => {
                    this.loading = false
                })
        },
        async getDealStatuses() {
            let params = {};
            params.filter = 'all';
            params.business_process = this.business_process;
            await this.$http
                .get(`admin/deal_status`, {
                    params: params,
                })
                .then(res => {
                    this.deal_statusItems = res.body.data.map(item => {
                        return {
                            id: item.id,
                            name: item.name,
                            url: {
                                name: 'deal_id',
                                params: {
                                    id: this.business_process
                                },
                                query: {
                                    filter: {
                                        deal_status_id: {
                                            eq: item.id
                                        }
                                    }
                                }
                            }
                        }
                    })
                })
                .catch(err => {
                    this.deal_statusItems = [];
                    this.$toastr.error(this.$t('failed_to_get_list_deal_statuses'))
                });
        },
        async getBusinessProcess() {
            let params = {};
            params.filter = 'all';
            await this.$http
                .get(`admin/business_process`, {
                    params: params,
                })
                .then(res => {

                    this.business_processItems = res.body.data.map(item => {
                        return {
                            ...item,
                            url: {
                                path: `/deal/${item.id}`,
                            }
                        }
                    })
                    // this.business_processItems.push({
                    //     'id': 0,
                    //     'name': this.$t('all_deals'),
                    //     'url': {
                    //         path: `/deal/0`,
                    //     }
                    // })
                })
                .catch(err => {
                    this.business_processItems = [];
                    this.$toastr.error(this.$t('failed_to_get_list_business_processes'))
                });
        },
        async activeDeal(item) {

            var _this = this

            this.loading = true;
            let params = {};
            if (item.active) {
                params.active = 1
            } else {
                params.active = 0
            }
            await this.$http
                .put(`admin/deal/active/${item.uuid}`, params)
                .then(res => {
                    if (params.active) {
                        this.$toastr.success(this.$t('deal_is_open'))
                    } else {
                        this.$toastr.success(this.$t('deal_is_closed'))
                    }

                })
                .catch(err => {
                    if (params.active) {
                        item.active = false;
                        this.$toastr.error(this.$t('deal_was_not_open'))
                    } else {
                        this.$toastr.error(this.$t('deal_was_not_closed'))
                    }
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })

                .finally(end => {
                    this.loading = false
                })
        },
        openDialogDealStatus(item) {
            if (this.userBlock === true) {
                return;
            }
            //  console.log(item.business_process_id)
            this.deal_uuid = item.uuid
            this.deal_status_edit = item.deal_status_id
            this.dialogDealStatus = true
        },
        closeDealStatus() {
            this.dialogDealStatus = false
            this.deal_status_edit = null
            this.deal_uuid = null
        },
        async setStatusDeal() {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()

            if (this.deal_status_edit) {
                formData.append('deal_status_id', this.deal_status_edit);
            }

            if (this.deal_uuid) {
                formData.append('deal_uuid', this.deal_uuid)
            }
            // Add
            await this.$http
                .put('admin/set/deal/status', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('deal_status_has_been_updated'))
                    this.closeDealStatus();
                    this.getDeals();
                })
                .catch(err => {
                    this.$toastr.error(this.$t('deal_status_has_not_been_updated'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })


        },
        showTask(item) {
            return {
                name: 'task.show',
                params: {
                    id: item.uuid
                }
            }
        },
        getFilterParams() {
            if(this.business_process > 0) {
                return {
                    business_process: this.business_process
                }
            } else {
                return {}
            }
        }
    }
}
</script>
